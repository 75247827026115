import request from '@/utils/request'
// /purse/getAmountMoney获取钱包余额
export const getAmountMoney = data => {
  return request({
    method: 'post',
    url: '/purse/getAmountMoney',
    data
  })
}
// /recharge充值
export const torecharge = data => {
  return request({
    method: 'post',
    url: '/recharge',
    data
  })
}
// /oss/uploadPhoto上传图片
export const uploadPhoto = data => {
  return request({
    method: 'post',
    url: '/oss/uploadPhoto',
    data,
    headers: { 'Content-Type': 'multipart/form-data' }
  })
}
// /purse/withdrawMoney取款
export const withdrawMoney = data => {
  return request({
    method: 'post',
    url: '/purse/withdrawMoney',
    params: data
  })
}
// /recharge/getBankInfo获取充值银行信息
export const getBankInfo = data => {
  return request({
    method: 'post',
    url: '/recharge/getBankInfo',
    data
  })
}
// /advertisement/get获取广告
export const getadv = data => {
  return request({
    method: 'post',
    url: '/advertisement/get',
    params: data
  })
}
// contact/getLineUrl
export const getLineUrl = data => {
  return request({
    method: 'post',
    url: '/contact/getLineUrl',
    params: data
  })
}
// /loan/loanInfo
export const loanInfo = data => {
  return request({
    method: 'post',
    url: '/loan/loanInfo',
    params: data
  })
}
